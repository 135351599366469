var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("baidu-map", {
        staticClass: "map-page",
        attrs: {
          center: _vm.center,
          zoom: _vm.zoom,
          "continuous-zoom": true,
          "scroll-wheel-zoom": true,
        },
        on: { ready: _vm.initMap },
      }),
      _vm.loading || (_vm.trackData && _vm.trackData.length > 0)
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "content-item" },
              [
                _c("speed", { on: { onSpeedChange: _vm.handleSpeedChange } }),
                _c(
                  "div",
                  { staticClass: "track-play" },
                  [
                    _c("a-icon", {
                      staticClass: "track-play-icon",
                      attrs: {
                        type: _vm.status === 1 ? "pause" : "play-circle",
                      },
                      on: { click: _vm.handlePlay },
                    }),
                    _c("a-slider", {
                      staticClass: "track-slider",
                      attrs: {
                        min: 0,
                        max: _vm.currentTrackPoints.length,
                        "default-value": 0,
                        tipFormatter: _vm.getWorkTime,
                      },
                      on: { afterChange: _vm.handleSliderAfterChange },
                      model: {
                        value: _vm.position,
                        callback: function ($$v) {
                          _vm.position = $$v
                        },
                        expression: "position",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "content-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.trackDate))]),
              _vm._v(" "),
              _c("span", [_vm._v("任务完成里程：" + _vm._s(_vm.trackMiles))]),
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.trackAddress))]),
              _vm._v(" "),
              _c("span", [_vm._v("任务完成时长：" + _vm._s(_vm.trackTimes))]),
            ]),
          ])
        : _c("a-empty", {
            staticClass: "empty",
            attrs: { image: _vm.simpleImage },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }