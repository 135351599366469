<template>
  <div class="container">
    <baidu-map class="map-page" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true"> </baidu-map>
    <div class="content" v-if="loading || (trackData && trackData.length > 0)">
      <div class="content-item">
        <speed @onSpeedChange="handleSpeedChange" />
        <div class="track-play">
          <a-icon :type="status === 1 ? 'pause' : 'play-circle'" class="track-play-icon" @click="handlePlay" />
          <a-slider :min="0" :max="currentTrackPoints.length" :default-value="0" class="track-slider" @afterChange="handleSliderAfterChange" :tipFormatter="getWorkTime" v-model="position" />
        </div>
      </div>
      <div class="content-item">
        <span>{{ trackDate }}</span> <span>任务完成里程：{{ trackMiles }}</span>
      </div>
      <div class="content-item">
        <span>{{ trackAddress }}</span> <span>任务完成时长：{{ trackTimes }}</span>
      </div>
    </div>
    <a-empty :image="simpleImage" v-else class="empty" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import testJson from './test.json'
import TrackPlayHelper from '@/views/monitor/monitorComponents/mappage/TrackPlayHelper.js'
import { VehiclePlayIcon } from '@/views/monitor/monitorComponents/utils/mapUtil'
import { Empty, Slider } from 'ant-design-vue'
import Speed from '@/views/monitor/monitorComponents/mapHistoryTrack/speed.vue'
export default {
  mixins: [TrackPlayHelper],
  components: {
    AEmpty: Empty,
    Speed,
    ASlider: Slider
  },
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      center: {
        lng: 104.114129,
        lat: 37.550339
      },
      zoom: 6,
      trackData: [],
      currentTrackPoints: [],
      trackDate: '',
      trackMiles: '',
      trackAddress: '',
      trackTimes: '',
      // 当前播放的车辆索引
      currentIndex: 0,
      isPlaying: false,
      lushuConfig: {
        playIcon: VehiclePlayIcon,
        // 不需要控制车辆方向
        enableRotation: true,
        // 轨迹播放时ICON上方显示的文字
        defaultContent: '',
        // 图标大小
        iconSize: { width: 52, height: 26 },
        // 图标的定位点相对于图标左上角的偏移值
        anchorSize: { width: 27, height: 14 }
      },
      // 状态： 0 初始状态，1正在运动，2停止
      status: 0,
      position: 0,
      // 当前车辆数组
      currentVehicleIdArray: [],
      currentTrackGroupHashMap: new Map()
    }
  },
  watch: {
    params(newVal, oldVal) {
      this.currentTrackGroupHashMap.clear()
      if (newVal && newVal.length > 0) {
        this.currentVehicleIdArray = newVal.map((p) => p.vehicleId)
      }
      this.trackData = newVal
    }
  },
  computed: {
    ...mapGetters(['userTenant'])
  },
  mounted() {
    this.initPubSub()
    // 轮播
    this.initTimer()
  },
  methods: {
    // 手动触发播放
    handlePlay() {
      console.log('handlePlay')
      const oldStatus = this.status
      if (this.status === 0 || this.status === 2) {
        // 当为初始状态与停止状态，点击即为运动中
        this.status = 1
      } else if (this.status === 1) {
        // 当状态为1，点击即为停止状态
        this.status = 2
      }
      // 播放历史轨迹
      this.mapTrackPlay(this.status, oldStatus)
      if (this.position === this.currentTrackPoints.length) {
        this.position = 0
      }
    },
    autoPlay() {
      console.log('autoPlay')
      if (!this.currentTrackPoints || this.currentTrackPoints.length === 0) {
        return
      }
      this.status = 1
      if (this.position === this.currentTrackPoints.length) {
        this.position = 0
      }
      this.lushu && this.lushu.start(0)
    },
    handleSpeedChange(val) {
      this.mapSetTrackPlaySpeed(val)
    },
    handleSliderAfterChange(val) {
      console.log(val)
      if (this.status !== 2) {
        // 如果非停止状态，则为运动状态
        // this.status = 1
      }
      // 轨迹播放跳转, 注意数组索引问题
      if (val > 0) {
        val = val - 1
      }
      this.mapTrackPlayJumpTo(val, this.status)
    },
    initPubSub() {
      this.tokenFinish = window.PubSub.subscribe('end', (msg, flag) => {
        if (flag) {
          setTimeout(() => {
            // 上一轮轮播结束
            this.isPlaying = false
            this.position = 0
            // 运动结束，状态为初始值
            if (this.status !== 2) {
              this.status = 0
            }
          }, 3000)
        }
      })

      // 订阅移动一步
      this.token = window.PubSub.subscribe('step', (msg, i) => {
        if (this.status === 1) {
          this.position++
          this.trackInfo = this.currentTrackPoints[this.position]
        }
      })
      this.tokenSlide = window.PubSub.subscribe('slide', (msg, i) => {
        console.log('slide', i)
        this.position = i
        this.trackInfo = this.currentTrackPoints[i]
      })
    },
    initTimer() {
      this.trackInterval = setInterval(() => {
        if (this.status === 2) {
          console.log('暂停', this.status)
          return
        }
        if (!this.isPlaying) {
          if (this.trackData && this.trackData.length > 0) {
            if (this.currentIndex >= this.currentVehicleIdArray.length) {
              this.currentIndex = 0
            }
            const vehicleId = this.currentVehicleIdArray[this.currentIndex]
            const currentObject = this.trackData.find((p) => p.vehicleId === vehicleId)
            if (currentObject && currentObject.points.length > 0) {
              this.trackAddress = currentObject.taskAddress
              this.trackMiles = currentObject.finishedOdom
              this.trackTimes = currentObject.finishedTime
              this.trackDate = currentObject.taskStartTime.substr(0, 10)
              this.currentTrackPoints = currentObject.points
              this.trackPlay(this.currentTrackPoints)
            }
            this.currentIndex++
          }
        }
      }, 3000)
    },
    // 获取中间的轨迹节点
    getMidPoint(data) {
      if (data.length > 100) {
        return data[100]
      } else {
        // 取中间坐标
        const n = data.length
        const index = Math.floor(n / 2)
        return data[index]
      }
    },
    // 轮播要求： 按车辆有序轮播
    trackPlay(data) {
      console.log('initTrack')
      // 判断是否正在播放中
      if (data.length > 0) {
        this.isPlaying = true
      }
      this.deleteLushuMarker()
      this.initHistoryTrackPlay(data, () => {
        // 当前车辆Id
        const vehicleId = this.currentVehicleIdArray[this.currentIndex]
        if (this.currentTrackGroupHashMap.has(vehicleId)) {
          this.currentTrackGroupArray = this.currentTrackGroupHashMap.get(vehicleId)
        } else {
          this.currentTrackGroupArray = this.parseTrackData(data)
          this.currentTrackGroupHashMap.set(vehicleId, [...this.currentTrackGroupArray])
        }
        this.drawTrackPolyline()
        setTimeout(() => {
          this.mapSetTrackPlaySpeed(2)
          this.autoPlay()
        }, 1000)
      })
    },
    // 轨迹地图初始化完成
    initMap(MapObj) {
      this.trackBMap = MapObj.BMap
      this.trackMap = MapObj.map
      this.zoom = 16
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat
      this.myGeo = new this.trackBMap.Geocoder()
    }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  beforeDestroy() {
    console.log('destroy')
    window.PubSub.unsubscribe(this.token)
    window.PubSub.unsubscribe(this.tokenFinish)
    window.PubSub.unsubscribe(this.tokenSlide)
    this.trackInterval && clearInterval(this.trackInterval)
    this.currentTrackGroupHashMap && this.currentTrackGroupHashMap.clear()
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  height: 89vh;
  flex-direction: column;
  .map-page {
    flex: 1;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin: 20px;
    .content-item {
      display: flex;
      flex: 1;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      span {
        flex: 1;
      }
      span:first-child {
        padding-right: 10px;
      }

      .track-play {
        display: flex;
        flex: 1;
        margin-left: 5px;
        justify-items: center;
        border: 1px solid #ebeef5;
        border-radius: 5px;
        padding-right: 5px;
        .track-play-icon {
          flex: 0 0 50px;
          min-width: 50px;
          align-self: center;
          font-size: 26px;
        }
        .track-slider {
          flex: 1;
        }
      }
    }
    .content-item:first-child {
      padding-bottom: 5px;
    }
  }
}
</style>
